import React, { useState, useEffect } from 'react';
import './App.css';
import { useSpeechSynthesis } from 'react-speech-kit';
let row1 = ['Escape', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'Insert', 'Pause', 'Delete'];
let row2 = ['²', '&', 'é', '"', "'", '(', '-', 'è', '_', 'ç', 'à', ')', '=', 'Backspace','NumLock', '/', '*', '-'];
let row2Maj = ['', '1', '2', '3', "4", '5', '6', '7', '8', '9', '0', '°', '+', ''];
let row3 = ['Tab','a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '^', '$', 'Enter','7','8','9','+'];
let row4 = ['CapsLock','q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'ù', '*', 'Enter','4','5','6'];
let row5 = ['Shift', '<', 'w', 'x', 'c', 'v', 'b', 'n', ',', ';', ':', '!', 'Shift', '1', '2', '3', 'Enter'];
let row6 = ['Control', 'Fn', 'Meta', 'Alt', ' ', 'AltGraph', 'ContextMenu', 'Control','0','.'];
let Clavier = () => {
  const [startConfig, setStartConfig] = useState(false),
    [startExercice, setStartExercice] = useState(false),
    [startCalibration, setStarCalibration] = useState(false),
    [confirmCalibration, setConfirmCalibration] = useState(false),
    [choice, setChoice] = useState(false),
    [exercise, setExercise] = useState({title:"être à l'aise avec les touches échap, espace, entré", content:['Escape', 'Enter', ' ', 'Enter', 'Enter', 'Escape' , ' '], completed:false, num:1, description:' '}),
    [touch, setTouch] = useState(' '),
    [computer, setComputeur] = useState(['ordinateur de bureau','Lap top']),
    [computerIndex, setComputeurIndex] = useState(0),
    [rowTouch, setRowTouch] = useState(row6),
    [step, setStep] = useState(1),
    [config,setConfig] = useState(1),
    [countdown, setCountdown] = useState(10),
    [keyPressed, setKeyPressed] = useState(''),
    [attemptCount, setAttemptCount] = useState(0),
    [currentKeyIndex, setCurrentKeyIndex] = useState(0),
    { speak, cancel, speaking, voices } = useSpeechSynthesis(),
    voice = voices.find(voice => voice.name === 'Microsoft Paul - French (France)') || 'default',
    handleSpeak = (speech) => {
      speak({ text:speech, voice, rate: 1.4, pitch: 0.5 });
    };
  useEffect(() => {
    if(step === 1 &&countdown==9)
      handleSpeak('Bienvenue, Si c’est votre première visite, patientez quelques secondes sinon appuyez sur la touche espace.')
    if (step === 1 && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (step === 1 && countdown === 0) {
      setStep(2);
    }
  }, [countdown]);
  useEffect(() => {
    const getTouchToSpeak = (index) => {
      return exercise.content[index] == " " ? 'Espace' : exercise.content[index] == "Enter" ? 'entrée' : exercise.content[index] =="Backspace" ? "retour" : exercise.content[index] == "Escape" ? 'Echap' : exercise.content[index];
    }
    const handleKeyPress = (event) => {
      if (step === 1 && event.key === ' ') {
        cancel();
        setStep(4);
      } else if (step === 2 && event.key === ' ') {
        cancel();
        setKeyPressed('');
        setStep(3);
      }
      else if (step === 3 && event.key === 'Escape') {
        cancel();
        setKeyPressed('');
        setStep(4);
      } 
      else if (step === 4 && event.key !== ' ' && event.key !== 'Escape'&&!confirmCalibration) {
        cancel();
        if(computerIndex < computer.length - 1){
          setComputeurIndex(computerIndex + 1);
          handleSpeak(`Option ${computerIndex + 2} : ${computer[computerIndex + 1]}`);
        }
      }
      else if (step === 4 && event.key === 'Escape'&&!confirmCalibration) {
        cancel();
        if(computerIndex > 0){
          setComputeurIndex(computerIndex - 1);
          handleSpeak(`Option ${computerIndex} : ${computer[computerIndex - 1]}`)
        }
      }
      else if (step === 4 && event.key === 'Escape'&&confirmCalibration) {
        cancel();
        setConfirmCalibration(false);
        handleSpeak(`Option ${computerIndex + 1} : ${computer[computerIndex]}`);
      }
      else if (step === 4 && event.key === ' '&&confirmCalibration) {
        cancel();
        if(computerIndex==1){
          row1 = ['Escape', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12', 'PrtSc', 'Insert', 'Delete','/','*','power']
          row2 = ['²', '&', 'é', '"', "'", '(', '-', 'è', '_', 'ç', 'à', ')', '=', 'Backspace', '-', '+', 'NumLock'];
          row2Maj = ['', '1', '2', '3', "4", '5', '6', '7', '8', '9', '0', '°', '+', ''];
          row3 = ['Tab','a', 'z', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '^', '$', '*', '7', '8', '9'];
          row4 = ['CapsLock','q', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', '%', 'Enter', '4', '5', '6'];
          row5 = ['Shift', '<', 'w', 'x', 'c', 'v', 'b', 'n', ',', ';', ':', '!', 'Shift', '1', '2', '3'];
          row6 = ['Control', 'Fn', 'Meta', 'Alt', ' ', 'AltGraph', 'Control', '0' ,'.', 'Enter'];
        }
        setStep(5);
      }
      else if (step === 4 && event.key === ' '&&!confirmCalibration) {
        cancel();
        handleSpeak(`Vous avez sélectionné l'Option ${computerIndex + 1} : ${computer[computerIndex]}, appuyez une nouvelle fois sur espace pour confirmer votre choix, si vous voulez resélectionner un choix cliqué sur échap`)
        setConfirmCalibration(true);
      }
      else if (step === 5 && event.key === 'Enter') {
        cancel();
        setKeyPressed('');
        setStep(6);
      }
      else if (step === 6 && event.key === 'Enter') {
        if(startConfig){
          cancel();
          setKeyPressed('');
          setConfig(1);
          setChoice(false);
          handleSpeak("Vous avez sélectionné l'option longue");
          setStep(7)
        }
        else{
          cancel();
          setKeyPressed('');
          setStartConfig(true);
          setChoice(true);
          handleSpeak("Maintenant, écoutez attentivement les consignes. Vous avez deux options. l'option longue qui vous indique la touche appuyé, la rangée de la touche et le nombre de touche à gauche ou à droite de la cible, lorsque vous faites une erreur, voici un éxemple, vous avez appuyé sur la touche espace qui se trouve sur la 6ème rangée du clavier, montez de 5 rangées pour atteindre la touche echap. Vous pouvez aussi opter pour l'option courte qui vous indique juste la touche appuyé, si vous devez monter ou descendre. Voici un éxemple, Entré, montez 5 fois. Pour choisir l'option longue cliquez sur la touche entrée, pour l'option courte cliqué sur éspace")
        }
      }
      else if (step === 6 && event.key === ' '){
        if(startConfig){
          cancel();
          setConfig(2);
          setKeyPressed('');
          handleSpeak("Vous avez sélectionné l'option courte");
          setStep(7)
        }
      }
      else if (step === 7 && event.key === 'Enter' && !startExercice) {
        setStartExercice(true);
        cancel();
        setTouch(exercise.content[0]);
        handleSpeak(`${exercise.num}${exercise.num==1?'er':'ème'} exercice, ${exercise.title} , ${exercise.description} , vous devez cliquez les touches dans l'ordre suivant ${exercise.content.map((item) => (item == " " ? 'Espace' : item == "Enter" ? 'entrée' : item=="Backspace" ? "retour" : item=="Escape" ? 'Echap' : item) +', , ')} . Ecoutez la touche que vous devez trouver et appuyez dessus.  vous avez 3 tentatives pour chaque touche, cliquer sur la touche ${getTouchToSpeak(0)}`)
      }
      else if (step === 7 && exercise.completed && event.key === ' '){
        cancel();
        setStartExercice(false);
        setKeyPressed('');
        setExercise({...exercise, completed:false});
        setAttemptCount(0);
        setCurrentKeyIndex(0);
      }
      else if (step === 7 && exercise.completed && event.key === 'Enter'){
        cancel();
        setKeyPressed('');
        setStartExercice(false);
        setExercise({...exercise, content:['f','j','j','f'], completed:false, title:"L'identification des touches F et J", num:2, description: 'Les lettre F et J servent à se repérer sur le clavier, elles vous permettent de positionner vos index. Index de gauche sur la touche F, index de droite sur la touche J. vous pouvez les reconnaitre car les touches ne sont pas lisses. Elles ont un marqueur pour vous aider à les reconnaitre.'});
        setAttemptCount(0);
        setCurrentKeyIndex(0);
      }
      else if(step === 7&&startExercice&&!exercise.completed){
        event.preventDefault();
        setTouch(exercise.content[0]);
        if (event.key === exercise.content[currentKeyIndex]) {
          setAttemptCount(0);
          if (currentKeyIndex === exercise.content.length - 1) {
            cancel();
            setChoice(true);
            handleSpeak("Félicitations, vous avez terminé l'exercice avec succès. pour refaire l'exercice cliquez sur espace, pour passer à l'exercice suivant, cliquez sur la touche entrée");
            setExercise({...exercise, completed:true});
            setTouch('Enter');
            setCurrentKeyIndex(0);
          } else {
            cancel();
            setKeyPressed('');
            handleSpeak(`Bravo, appuyez maintenant sur la touche ${getTouchToSpeak(currentKeyIndex + 1)}.`);
            setTouch(exercise.content[currentKeyIndex + 1]);
            setCurrentKeyIndex(prevIndex => prevIndex + 1);
          }
        } else {
          if (attemptCount < 3) {
            cancel();
            setAttemptCount(prevCount => prevCount + 1);
            setKeyPressed(event.key);
            setRowTouch(getRowComplete(exercise.content[currentKeyIndex]));
            setTouch(exercise.content[currentKeyIndex])
            handleSpeak(config == 1 ? `Vous avez appuyé sur la touche ${event.key == 'Escape' ? 'échap' : event.key == ' ' ? 'espace' : event.key == 'Enter' ? 'Entrée' : event.key == ',' ? 'virgule' : event.key == ";" ? 'point virgule' : event.key == ':' ? 'deux points' : event.key == "!" ? "point d'exclamation" : event.key == "<" ? "inférieur" : event.key} qui est sur la ${getRow(event.key)}${getRow(event.key) != 1 ? 'eme' : 'ère'} rangé du clavier, ${getKeyPosition(event.key,getRowComplete(exercise.content[currentKeyIndex]),exercise.content[currentKeyIndex])}`: `${event.key == 'Enter' ? 'Entrée' : event.key == ',' ? 'virgule' : event.key == ";" ? 'point virgule' : event.key == ':' ? 'deux points' : event.key == "!" ? "point d'exclamation" : event.key == "<" ? "inférieur" : event.key}, ${getKeyPosition(event.key,getRowComplete(exercise.content[currentKeyIndex]),exercise.content[currentKeyIndex])}`)
          } else if (attemptCount === 3) {
            cancel();
            handleSpeak(`Vous avez échoué à appuyer sur la touche ${getTouchToSpeak(currentKeyIndex)}. La prochaine touche est ${getTouchToSpeak(currentKeyIndex + 1)}.`);
            setAttemptCount(0);
            setCurrentKeyIndex(prevIndex => prevIndex + 1);
            setRowTouch(getRowComplete(exercise.content[currentKeyIndex + 1]));
            setTouch(exercise.content[currentKeyIndex + 1])
          }
        }
      }
      else if (step !== 1&&step!==4) {
        event.preventDefault();
        cancel();
        setKeyPressed(event.key);
        handleSpeak(config == 1 ? `Vous avez appuyé sur la touche ${event.key == 'Enter' ? 'Entrée' : event.key == ',' ? 'virgule' : event.key == ";" ? 'point virgule' : event.key == ':' ? 'deux points' : event.key == "!" ? "point d'exclamation" : event.key == "<" ? "inférieur" : event.key} qui est sur la ${getRow(event.key)}${getRow(event.key) != 1 ? 'eme' : 'ère'} rangé du clavier, ${getKeyPosition(event.key,rowTouch,touch)}`: event.key == 'Enter' ? 'Entrée' : event.key == ',' ? 'virgule' : event.key == ";" ? 'point virgule' : event.key == ':' ? 'deux points' : event.key == "!" ? "point d'exclamation" : event.key == "<" ? "inférieur" : event.key)
      }
    };
    if(step === 2){
      cancel();
      handleSpeak('C’est votre première visite. Pour faciliter votre navigation, vous allez utiliser la touche espace pour valider les menus et la touche échap pour revenir à l’écran précédent. La touche espace est sur la ligne de touches la plus vers vous, en bas du clavier au milieu. Appuyez dessus maintenant, afin de continuer. Si vous ne la trouvez pas, nous vous dirons la touche que vous avez touchée.');
    }else if(step === 3){
      setTouch('Escape');
      setRowTouch(row1);
      handleSpeak('Bravo, vous avez trouvé la touche espace. Trouvez désormais la touche echap. C’est généralement la première touche de la première ligne du clavier, en haut à gauche.');
    }else if(step === 4){
      if(!startCalibration){
        handleSpeak(`Calibration, pour connaitre votre clavier, vous devez le choisir dans la liste suivante : appuyez sur n'importe quelle touche pour avancer dans la liste, sur espace pour valider votre ordinateur, et sur echap pour revenir au menu si vous vous êtes trompé. Option ${computerIndex + 1} : ${computer[computerIndex]}`);
        setTouch(null);
        setStarCalibration(true)
      }
    }else if(step === 5){
      setTouch('Enter');
      setRowTouch(computerIndex==1?row4:row3);
      handleSpeak(`Vous avez choisi ${computer[computerIndex]}, Trouvez désormais la touche Entrée. C'est la plus grande touche de la 4ème rangé à droite`);
    }else if(step === 6&&!startConfig){
      handleSpeak("Bravo, vous avez trouvé la touche Entrée. A partir de maintenant vous pouvez commencer des exercices mais avant tout, nous allons configurer vos préférences. Premièrement retrouvez la touche entrée, pour rappel c'est la plus grande touche de la 4ème rangé à droite");
    }else if(step === 7&&!startExercice){
      setChoice(false);
      handleSpeak(`Vous allez maintenant commencer le ${exercise.num}${exercise.num==1?'er':'ème'} exercice avec l'option ${config == 1 ? 'longue' : 'courte'} , Cliquez sur la touche entrée pour commencer l'exercice`);
    }
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [step,startConfig, startExercice,touch,rowTouch,  currentKeyIndex, attemptCount, exercise.num, computerIndex, confirmCalibration]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
            <p>Bienvenue, <br/>
            Si c’est votre première visite, patientez {countdown} secondes sinon appuyez sur la touche espace.</p>
        );
      case 2:
        return (
            <p>
              C’est votre première visite. Pour faciliter votre navigation, vous allez utiliser la touche espace pour valider les menus et la touche échap pour revenir à l’écran précédent.
              La touche espace est sur la ligne de touches la plus vers vous, en bas du clavier au milieu. Appuyez dessus maintenant, afin de continuer. Si vous ne la trouvez pas, nous vous dirons la touche que vous avez touchée.
            </p>
        );
      case 3:
        return (
            <p>Bravo, vous avez trouvé la touche espace. Trouvez désormais la touche echap. <br/>
              C’est généralement la première touche de la première ligne du clavier, en haut à gauche. </p>
        );
      case 4:
        return (
          <div>
            <p>Calibration: pour connaitre votre clavier, vous devez le choisir dans la liste suivante : appuyez sur n'importe quelle touche pour avancer dans la liste, sur espace pour valider votre ordinateur, et sur echap pour revenir au menu si vous vous êtes trompé.</p>
            <p>Option {computerIndex + 1} : {computer[computerIndex]}</p>
          </div>
        );
      case 5:
        return (
          <div>
            <p>Vous avez choisi {computer[computerIndex]}, Trouvez désormais la touche Entrée. <br/> C'est la plus grande touche de la 4ème rangé à droite</p>
          </div>
        );
      case 6:
        return (
          <div>
            {!startConfig ? 
            <p>
              Bravo, vous avez trouvé la touche Entrée. <br/>
              A partir de maintenant vous pouvez commencer des exercices mais avant tout, nous allons configurer vos préférences .<br/>
              Premièrement retrouvez la touche entrée, pour rappel c'est la plus grande touche de la 4ème rangé à droite</p>:
            <p> 
              Choix des options: <br/>
              -cliquez sur Entrée pour choisir l'option longue <br/>
              -cliquez sur Espace pour choisir l'option courte
            </p>
            }
          </div>
        );
        case 7:
          return (
            <>
              <p>
                Exercice {exercise.num}: {exercise.title} <br/>
                {exercise.description}
              </p>
                {startExercice&&(
                  <p>Vous devez cliquez les touches dans l'ordre suivant:<br/> {exercise.content.map((item) => (item == " " ? 'Espace' : item == "Enter" ? 'entrée' : item=="Backspace" ? "retour" : item=="Escape" ? 'Echap' : item) +', ')}</p>
                  
                )}
                {exercise.completed&&(
                  <p>Félicitations, vous avez terminé l'exercice avec succès. pour refaire l'exercice cliquez sur espace, pour passer à l'exercice suivant, cliquez sur la touche entrée</p>
                )}
            </>
          );
      default:
        return null;
    }
  };
  function getRowComplete(key) {
    if (row1.includes(key)) return row1;
    if (row2.includes(key)) return row2;
    if (row3.includes(key)) return row3;
    if (row4.includes(key)) return row4;
    if (row5.includes(key)) return row5;
    if (row6.includes(key)) return row6;
    return row1;
  }
  function getRow(key) {
    if (row1.includes(key)) return 1;
    if (row2.includes(key)) return 2;
    if (row3.includes(key)) return 3;
    if (row4.includes(key)) return 4;
    if (row5.includes(key)) return 5;
    if (row6.includes(key)) return 6;
    return -1;
  }
  const getKeyPosition = (key,row,touch) => {
    const rowIndex = getRow(key);
    const rowDifference = getRow(touch) - rowIndex;
    const direction = rowDifference > 0 ? 'descendez' : 'montez';
    const absDifference = Math.abs(rowDifference);
    let positionText = getRow(touch) != rowIndex ? (config == 1 ? `${direction} de ${absDifference} rangées pour atteindre la touche ${touch == 'Escape' ? 'Echap' : touch == ' ' ? 'espace' : touch == 'Enter' ? 'Entrée' : touch == ',' ? 'virgule' : touch == ";" ? 'point virgule' : touch == ':' ? 'deux points' : touch == "!" ? "point d'exclamation" : touch == "<" ? "inférieur" : touch}.` : `${direction} ${absDifference == 1 ? 'une' : absDifference} fois`): (config == 1 ? `vous êtes sur la même rangée que la touche ${touch == 'Escape' ? 'Echap' : touch == ' ' ? 'espace' : touch == 'Enter' ? 'Entrée' : touch == ',' ? 'virgule' : touch == ";" ? 'point virgule' : touch == ':' ? 'deux points' : touch == "!" ? "point d'exclamation" : touch == "<" ? "inférieur" : touch} , ` : ', ');
    if (rowDifference === 0) {
      const spaceIndex = row.indexOf(touch)
      const keyIndex =  row.indexOf(key);
      const moveDirection = keyIndex < spaceIndex ? 'droite' : 'gauche';
      const moveAmount = Math.abs(keyIndex - spaceIndex);
      positionText += config == 1 ? ` Vous devez vous déplacer de ${moveAmount} touches vers la ${moveDirection}.` : `${moveAmount} touches à ${moveDirection}`;
    }
    return positionText;
  };
  return (
    <div className="container">
      <div className="note">
        {renderStep()}
        {(step !== 1&&step!==4) && keyPressed && (
          <div>
            <p>{config == 1 ? `Vous avez appuyé sur la touche ${keyPressed} qui est sur la ${getRow(keyPressed)}${getRow(keyPressed) != 1 ? 'eme' : 'ère'} rangée du clavier. ${getKeyPosition(keyPressed,rowTouch,touch)}` : `${keyPressed} ${getKeyPosition(keyPressed,rowTouch,touch)}`}</p>
          </div>
        )}
      </div>
      <div id="keyboard" className={computerIndex == 1&&startCalibration&&step>=5?'laptop':null}>
        <ul className="cf">
          {row1.map((item,index) => 
            <li key={index} className={touch == item ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className="key fn"><span className="esc">{item=="Escape" ? "Esc" : item}</span></button></li>
          )}
        </ul>
        <ul className="cf" id="numbers">
          {row2.map((item,index) => 
            <li key={index} className={touch == item ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className={`key ${item=="alt" ? "alt" : ""}`} id={item=="Backspace" ? 'delete' : item=="NumLock" ? 'num' : item=='²' ? 'expo' : null}>{row2Maj[index] ? <b>{row2Maj[index]}</b>:null}<span>{item == "NumLock" ? 'Vernum' : item}</span></button></li>
            )}
        </ul>
        <ul className="cf" id="qwerty">
          {row3.map((item,index) => 
            <li key={index} className={touch == item ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className={`key ${item=="Enter" ? 'enter1' : ''}`} id={item=="Tab" ? 'tab' : item=="+" ? 'plus' : ''}><span>{item}</span></button></li>
          )}
        </ul>
        <ul className="cf" id="asdfg">
          {row4.map((item,index) => 
            <li key={index} className={touch == item ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className={`key ${item=="Enter" ? computerIndex==1&&step>=5 ?'enter1' : 'enter2' : ''}`} id={item=="CapsLock" ? 'caps' : item=="Enter" ? 'enter' : null}><span>{item==="Enter"&&computerIndex===0  ? '' : item}</span></button></li>
          )}
        </ul>
        <ul className="cf" id="zxcvb">
          {row5.map((item,index) => 
            <li key={index} className={touch == item ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className={`key ${index==0 ? 'shiftleft' : (computerIndex==1&&step>=5 ? (index==row5.length - 4 ? 'shiftright' : '') : (index==row5.length - 5 ? 'shiftright' : ''))}`} id={item=="Enter" ? 'enter' : null}><span>{item}</span></button></li>
          )}
        </ul>
        <ul className="cf" id="bottomrow">
          {computerIndex==1&&startCalibration&&step>=5 ?
            row6.slice(0, -3).map((item,index) => 
            <li key={index} className={touch == item || (choice&&item==' ')  ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className="key" id={item==" " ? 'spacebar' : item=="Alt" ? 'alt' : item=='AltGraph' ? 'altgr': null}><span>{item=="Control" ? 'Ctr' : item=="AltGraph" ? 'AltGr' : item=="ContextMenu" ? 'Cntxt' : item}</span></button></li>)
            :
            row6.slice(0, -2).map((item,index) => 
            <li key={index} className={touch == item || (choice&&item==' ')  ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className="key" id={item==" " ? 'spacebar' : null}><span>{item=="Control" ? 'Ctr' : item=="AltGraph" ? 'AltGr' : item=="ContextMenu" ? 'Cntxt' : item}</span></button></li>)
          }
          <ol className="cf">
                <li><button type="button" className="key c37" id="left"><span>&#9666;</span></button></li>
              <li>
                    <button type="button" className="key c38" id="up"><span>&#9652;</span></button>
                    <button type="button" className="key c40" id="down"><span>&#9662;</span></button>
              </li>
                <li><button type="button" className="key c39" id="right"><span>&#9656;</span></button></li>
          </ol>
          {computerIndex==1&&startCalibration&&step>=5 ?
            row6.slice(-3).map((item,index) => 
            <li key={index} className={touch == item || (choice&&item==' ')  ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className="key"><span>{item=="Control" ? 'Ctr' : item=="AltGraph" ? 'AltGr' : item=="ContextMenu" ? 'Cntxt' : item}</span></button></li>)
            :
            row6.slice(-2).map((item,index) => 
            <li key={index} className={touch == item || (choice&&item==' ')  ? 'target' : keyPressed == item ? 'clicked' : null}><button type="button" className="key" id={item=="0" ? 'zero' : null}><span>{item=="Control" ? 'Ctr' : item=="AltGraph" ? 'AltGr' : item=="ContextMenu" ? 'Cntxt' : item}</span></button></li>)
            }
        </ul>
      </div>
    </div>
  );
};

export default Clavier;